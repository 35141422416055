import React from 'react';
import { Container, Row, Col, Tab, Nav, Button, Form } from 'react-bootstrap';
import API from '../../service/api.service';
import { MyProfile } from './my-profile/my-profile.component';

export const Profile: React.FC = () => {

  

  return (
    <Container fluid className="bg-white p-4" style={{ minHeight: '100vh' }}>
      <Tab.Container defaultActiveKey="profile">
        <Row>
          <Col md={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="profile">My Profile</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="payment">Payment Method</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="password">Change Password</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Col md={9}>
            <Tab.Content>
              <Tab.Pane eventKey="profile">
                <MyProfile />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};
