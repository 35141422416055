import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectAuthDetails = createSelector(
  (state: RootState) => state.auth,
  (auth) => ({
    isAuthenticated: auth.isAuthenticated,
    user: auth.customer,
    access_token: auth.access_token,
    error: auth.error || '',
    status: auth.status,
    toaster: auth.toaster,
    reset_password_token: auth.reset_password_token,
    patient_email: auth.patient_email,
    patient_id: auth.customer?.patient_id
  })
);
