import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitalState, StatusType } from '../../types/common.type';
import { getPaymentMethods } from '../actions/payment.action';
import { PaymentCard, PaymentMethods, PaymentMethodsResponse } from '../../types';

interface PaymentsInitialState extends InitalState {
  default_payment_methods: PaymentCard[];
  backup_payment_methods: PaymentCard[];
  payment_methods: PaymentCard[];
  status: StatusType;
}

const initialState: PaymentsInitialState = {
  payment_methods: [],
  backup_payment_methods: [],
  default_payment_methods: [],
  status: StatusType.Idle
};

const paymentsReducer = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action: PayloadAction<PaymentMethodsResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          }
          else {
            const payment_cards = action.payload.payment_cards && action.payload.payment_cards.length ? action.payload.payment_cards : [];
            const source_id: number = action.payload.source_id || 0;
            const secondary_source_id = action.payload.secondary_source_id || 0;
            const default_payment_methods = payment_cards.filter((item: { source_id: number; }) => item.source_id === source_id);

            const backup_payment_methods = payment_cards.filter((item: { source_id: number; }) => item.source_id === secondary_source_id);

            const payment_methods = payment_cards.filter(
              (item: { source_id: number; }) => item.source_id !== source_id && item.source_id !== secondary_source_id
          );                      

          state.default_payment_methods = default_payment_methods || [];
          state.backup_payment_methods = backup_payment_methods || [];
          state.payment_methods = payment_methods || [];
          state.status = StatusType.Succeeded;
          }
        }

      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.status = StatusType.Failed;
        const error: string = typeof action.payload === 'object' && action.payload !== null ? '' : (action.payload as string);
        state.error = error || 'Failed to fetch payment detail';
        state.flag = 401;
      })      
  }
});

export default paymentsReducer.reducer;
