import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentMethodInput, PaymentMethodsResponse, changePaymentMethodInput, partialPaymentInput, paymentMethodRejectResponse } from '../../types';
import API from '../../service/api.service';

export const getPaymentMethods = createAsyncThunk<PaymentMethodsResponse, { data: PaymentMethodInput }, { rejectValue: any }>(
  'payment/methods',
  async ({ data }, { rejectWithValue }) => {
    try {

      const response = await API({
        api: `details/get_customer_payment_source?patient_id=${data.patient_id}`,
        type: 'GET'
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({ message: (response.original_text), flag: response.flag });
      }

      let result = (response?.payment_sources && response.payment_sources.length) ? response.payment_sources : [];
      result = {
        payment_cards: result,
        source_id: data.source_id,
        secondary_source_id: data.secondary_source_id
      };

      return result;
    } catch (err: any) {
      return rejectWithValue({ flag: err.flag, message: err.response?.data?.message || 'Failed to fetch contract list' });
    }
  }
);

export const setPaymentMethods = createAsyncThunk<{}, { data: changePaymentMethodInput }, { rejectValue: paymentMethodRejectResponse }>(
  'setPayment/methods',
  async ({ data }, { rejectWithValue }) => {
    try {

      if (!data.source_id || !data.secondary_source_id) {
        return rejectWithValue({ message: 'Please fill login form properly!' });
      }

      const body = {
        contract_id: data.contract_id,
        source_id: data.source_id,
        secondary_source_id: data.secondary_source_id
      };

      const response = await API({
        api: `payment/change_payment_methods`,
        body: body,
        type: 'POST'
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({ message: (response.original_text), flag: response.flag });
      }

      const result = response;
      return result;
    } catch (err: any) {
      return rejectWithValue({ flag: err.flag, message: err.response?.data?.message || 'Failed to fetch auth details' });
    }
  }
);

export const partialPayment = createAsyncThunk<{}, { data: partialPaymentInput }, { rejectValue: paymentMethodRejectResponse }>(
  'setPayment/methods',
  async ({ data }, { rejectWithValue }) => {
    try {

      const body = {
        contract_id: data.contract_id,
        source_id: data.source_id,
        payment_source_type: data.payment_source_type,
        is_not_partial: 0,
        actual_amount: data.amount
      };

      const response = await API({
        api: `payment/change_payment_methods`,
        body: body,
        type: 'POST'
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({ message: (response.original_text), flag: response.flag });
      }

      const result = response;
      return result;
    } catch (err: any) {
      return rejectWithValue({ flag: err.flag, message: err.response?.data?.message || 'Failed to fetch auth details' });
    }
  }
);