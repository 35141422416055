import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardTabType, Toaster } from '../../types';
import { default_toaster_values } from '../../service/constants';

interface SharedInitialState {
  toaster: Toaster | null;
  error?: string | null;
  flag?: number | null;
  dashboardTab: DashboardTabType,
  status: boolean;
}

const initialState: SharedInitialState = {
  toaster: default_toaster_values,
  error: null,
  flag: null,
  dashboardTab: DashboardTabType.OVERVIEW,
  status: false
} as SharedInitialState;

const sharedReducer = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    showToaster: (state, action: PayloadAction<Toaster>) => {
      state.toaster = {
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
        duration: action.payload.duration
      }
    },
    switchDashboardTab: (state, action: PayloadAction<DashboardTabType>) => {
      state.dashboardTab = action.payload;
    },
  }
});

export default sharedReducer.reducer;
export const { showToaster, switchDashboardTab } = sharedReducer.actions;
