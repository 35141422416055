import { defaultAppDefaults } from "./constants";
import { customGetItem, customSetItem, getAccessToken } from "./local-storage.service";

interface HttpApiParams {
  api: string;
  body?: Record<string, any>;
  type?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'form';
  content_type?: string;
}

export interface HttpApiResponse<T = any> {
  data?: T;
  is_error: number;
  original_text: string;
  flag: number;
  status: number;
  message?: string;
  tracking_id?: string;
  request_id?: number;
  err?: string;
  original_override_text?: string;
  override_text?: string;
}

// TODO: will fetch from env
// const baseUrl = process.env.REACT_APP_API_URL || "https://testcustomer.financemutual.com/api/customer/";
const baseUrl = "https://testcustomer.financemutual.com/api/customer/";

const get_device_id = () => {
  let device_id = customGetItem('device_id');
  if (!device_id) {
    device_id = guid();
    customSetItem('device_id', device_id);
  }
  return device_id;
}

async function httpApiCall(params: HttpApiParams): Promise<any> {
  let access_token: any = getAccessToken();
  console.log(access_token, "access_token")
  params.body = { ...defaultAppDefaults, ...params.body };
  params.body = params.type === 'POST' ? { ...params.body, ...{ device_info: device_info() } } : params.body;

  if (!customGetItem('user')) {
    const guidLog = guid();
    customSetItem('user', guidLog);
  }
  
  const headers: Record<string, string> = {
    Accept: "*/*",
    "x-client-url": window.location.href,
    "x-lan-code": "en",
    "device_id": get_device_id(),
    "Authorization": access_token
  };

  let body: any = params.body || {};  

  if (params.type === 'form') {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key] as string | Blob); // Ensure type compatibility
    });
    body = formData;
    headers['Content-Type'] = 'multipart/form-data';
  } else if (params.type && params.type !== 'GET') {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify(body);
  } else {
    body = undefined; // Clear the body for GET requests
  }

  const url = baseUrl + params.api;

  // Retry logic
  const maxRetries = 1;
  let attempt = 0;
  while (attempt < maxRetries) {
    try {
      const response = await fetch(url, {
        method: params.type || "GET",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        // Handle non-2xx status codes
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      attempt++;
      if (attempt >= maxRetries) {
        console.error('API call failed after 3 attempts:', error);
        return { message: 'Failed to fetch data after multiple attempts' };
      }
    }
  }

  return { message: 'Unexpected error occurred' };
}

const device_info = () => {
  let window: any = Window;
  let document: any = Document;
  // Opera 8.0+
  const isOpera =
    navigator.userAgent.indexOf(' OPR/') >= 0 ||
    navigator.userAgent.indexOf('Opera') >= 0;
  // Firefox 1.0+
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // Edge 20+
  const isEdge = navigator.userAgent.indexOf('Edge') > -1;
  // Internet Explorer 6-11
  const isIE =
    typeof document !== 'undefined' && !!document.documentMode && !isEdge;
  // Chrome 1 - 71
  const isChrome = !isOpera && !isEdge && !isSafari;
  // Blink engine detection
  const isBlink = (isChrome || isOpera) && window && !!window.CSS;
  const browserInfo = {
    isFirefox,
    isChrome,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isBlink,
  };
  const platform =
    navigator && navigator.platform ? navigator.platform : 'unknown';
  const userAgent =
    navigator && navigator.userAgent ? navigator.userAgent : 'unknown';
  return {
    browser: browserInfo,
    platform,
    userAgent,
  };
}

const guid = () => {
  const a = new Date();
  const nav = window.navigator;
  const screen = window.screen;
  let guid = nav.mimeTypes.length.toString();
  guid += nav.userAgent.replace(/\D+/g, '');
  guid += nav.plugins.length;
  guid += screen.height || '';
  guid += screen.width || '';
  guid += screen.pixelDepth || '';
  guid += a.getTime();
  return guid;
}

export async function downloadApi(params: HttpApiParams) {
  try {
    let access_token: any = getAccessToken();

    const headers: Record<string, string> = {
      Accept: "*/*",
      "x-client-url": window.location.href,
      "x-lan-code": "en",
      "device_id": get_device_id(),
      "Authorization": access_token
    };

    let url = baseUrl + params.api;
    const fetchOptions: any = {
      method: params.type,
      responseType: 'blob' as 'json', 
      observe: 'response' as 'body',
      headers
    };
  
    if (params.type === 'POST' && params.body) {
      fetchOptions.body = params.body;
    } else  if (params.type === 'GET' && params.body){
      const body = new URLSearchParams(params.body).toString();
      url = `${url}?${body}`;
    }
    let response: any = await fetch(url, fetchOptions);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export default httpApiCall;
