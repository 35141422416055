import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitalState, StatusType } from '../../types/common.type';
import { ContractDetailResponse, contractDetails, contractList, ContractListResponse, TransactionDetailResponse, trnsactionDetails } from '../actions/contracts.action';
import { ContractDetail, CustomerContractList, TransactionType } from '../../types/contract.type';
import { routes_constants } from '../../service/constants';

interface ContractsInitialState extends InitalState {
  contractList: CustomerContractList[];
  contractDetail: ContractDetail | null;
  transactionDetails: TransactionType[];
  status: StatusType;
}

const initialState: ContractsInitialState = {
  contractList: [],
  contractDetail: null,
  transactionDetails: [],
  status: StatusType.Idle
};

const contractsReducer = createSlice({
  name: 'contracts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contractList.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(contractList.fulfilled, (state, action: PayloadAction<ContractListResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          }
          else {
            state.contractList = action.payload.paginated_contracts;
            state.status = StatusType.Succeeded;
          }
        }

      })
      .addCase(contractDetails.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch contract detail';
        state.flag = 401;
      })
      .addCase(contractDetails.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(contractDetails.fulfilled, (state, action: PayloadAction<ContractDetailResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.message
            state.flag = action.payload.flag
          }
          else {
            state.contractDetail = action.payload.contractDetail;
            state.status = StatusType.Succeeded;
            if (action.payload.navigate) {
              action.payload.navigate(`${routes_constants.dashboard}?contract_id=${action.payload.contractDetail.contract_id}`);
            }
          }
        }

      })
      .addCase(contractList.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch contract list';
        state.flag = 401;
      })
      .addCase(trnsactionDetails.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(trnsactionDetails.fulfilled, (state, action: PayloadAction<TransactionDetailResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.message
            state.flag = action.payload.flag
          }
          else {
            state.transactionDetails = action.payload.transaction;
            state.status = StatusType.Succeeded;
          }
        }

      })
      .addCase(trnsactionDetails.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch transaction list';
        state.flag = 401;
      })
  }
});

export default contractsReducer.reducer;
