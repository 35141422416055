import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectPaymentDetails = createSelector(
  (state: RootState) => state.payment,
  (payment) => ({
    paymentMethods: payment.payment_methods,
    backup_payment_methods: payment.backup_payment_methods,
    default_payment_methods: payment.default_payment_methods
  })
);

