import React, { useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { Toaster } from '../../../types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { showToaster } from '../../../redux/reducers/shared.reducer';
import { default_toaster_values } from '../../../service/constants';


export const NotificationToaster: React.FC<Toaster> = ({
  status=false,
  message,
  type = 'info',
  duration = 3000,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(showToaster(default_toaster_values));
    }, duration);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  console.log(status);
  if (!status) return null;

  const handleShowToaster = () => {
    dispatch(showToaster(default_toaster_values));
  }

  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast onClose={handleShowToaster} show={status} bg={type} autohide>
        <Toast.Header>
          <strong className="me-auto">{type.charAt(0).toUpperCase() + type.slice(1)}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};