import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../service/api.service';
import { AuthInput, AuthResponse, LoginAfterReset } from '../../types';
import { persistor } from '../store';

export interface AuthRejectResponse {
  is_error?: number,
  flag?: number,
  tracking_id?: string,
  request_id?: number,
  message: string,
  navigate:Function;
}

export const authenticate = createAsyncThunk<AuthResponse, { data: AuthInput, navigate: Function}, { rejectValue: AuthRejectResponse }>(
  'auth/authenticate',
  async ({data, navigate}, { rejectWithValue }) => {
    try {
    
      if(!data.email || !data.password){
        return rejectWithValue({message: 'Please fill login form properly!', navigate});
      }

      const body = {
        patient_email: data.email,
        patient_password: data.password
     };

      const response = await API({
        api: `auth/email_login`,
        body: body,
        type: 'POST'
      });      
      
      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({message: (response.original_text), navigate, flag: response.flag});
      }

      const result = response?.data ? {...response.data, navigate} : {...response, navigate};

      return result;
    } catch (err: any) {
      return rejectWithValue({navigate, flag: err.flag, message: err.response?.data?.message || 'Failed to fetch auth details'});
    }
  }
);

export const logout = createAsyncThunk<boolean, void>(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API({
        api: `auth/logout`,
        type: 'POST'
      });

      if (response.is_error) {
        return rejectWithValue(response.original_text);
      }

      await persistor.purge();
      return true;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);


export const loginAfterResetPass = createAsyncThunk<LoginAfterReset, LoginAfterReset>(
  'auth/loginAfterReset',
  async (payload, { rejectWithValue }) => {
    try {

      return payload;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);