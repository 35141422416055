
export const customGetItem = (key: string) => {
    let customer: any = localStorage.getItem("finance_mutual_storage");
    if (customer) {
    customer = JSON.parse(customer);
    }
    if (!customer) return null;
    if (typeof customer[key] === "object") customer[key] = JSON.stringify(customer[key]);
    return customer[key] || null;
}
  
 export const customSetItem = (key: string, value: any) => {
    let customer: any = localStorage.getItem("finance_mutual_storage");
    if (customer) {
    customer = JSON.parse(customer);
    }
    if (!customer) {
    localStorage.setItem("finance_mutual_storage", JSON.stringify({ [key]: value }));
    } else {
    customer[key] = value;
    localStorage.setItem("finance_mutual_storage", JSON.stringify(customer));
    }
}

export const getAccessToken = () => {
    let root: any = localStorage.getItem("persist:root");
    const parsedAuth = JSON.parse(root);
    const auth = JSON.parse(parsedAuth.auth);
    if(auth.access_token) {
        const access_token = auth.access_token;
        return access_token;
    }
    return '';
}