import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import LoginComponent from '../components/login/login.lazy';
import DashboardComponent from '../components/layout/dashboard/dashboard.lazy';
import PrivateRoutes from './PrivateRouting';
import ContractValidateComponent from '../components/contract-validate/contract-validate.component';
import ForgetPasswordLazyComponent from '../components/forget-password/forgot-password.lazy';
import SetPasswordLazyComponent from '../components/contract-validate/set-password/set-password.lazy';
import LayoutLazyComponent from '../components/LayoutComponent/LayoutComponent.lazy';
import { Profile } from '../components/profile/profile.component';
import PartialPaymentLazyComponent from '../components/partial-payment/partial-payment.lazy';

const Routing: React.FC = () => {
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
          <Route path="/" element={<Navigate to="login"/>} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/register" element={<ContractValidateComponent />} />
          <Route path="/change-password" element={<SetPasswordLazyComponent />} />
          <Route path="/forgot" element={<ForgetPasswordLazyComponent />} />
          <Route element={<LayoutLazyComponent />}>
            <Route path="/app" element={<PrivateRoutes />}>
            <Route path="dashboard" element={<DashboardComponent />} />  
            <Route path="contract-details/:id" element={<DashboardComponent />} />  
            <Route path="profile" element={<Profile />} />
            <Route path="partial-payment" element={<PartialPaymentLazyComponent />} />
            </Route>
          </Route>
          {/* <Route element={<LayoutLazyComponent />}>
        </Route> */}
      </>
    )
  );

  return <RouterProvider router={router} />;
  
  // const routes = useRoutes([
  //   { path: '/', element: <LoginComponent /> },
  //   { path: '/login', element: <LoginComponent /> },
  //   { path: '/register', element: <ContractValidateComponent /> },
  //   { path: '/change-password', element: <SetPasswordLazyComponent /> },
  //   { path: '/forgot', element: <ForgetPasswordLazyComponent /> },
  //   { path: '*', element: <InvalidComponent /> },
  //   { path: '', element: <PrivateRoutes />,
  //     children: [
  //       // { path: '', redirect: 'dashboard' /> },
  //       { path: 'dashboard', element: <DashboardComponent /> },
  //       // Add other protected routes here
  //     ],
  //   },
  // ]);

  // return routes;
};

export default Routing;
