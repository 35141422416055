import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Button, Form } from "react-bootstrap";
import API from '../../../service/api.service';

// Define the Yup validation schema
const schema = yup.object().shape({
  dob: yup.date().required("Date of Birth is required"),
  address: yup.string().required("Address is required"),
  zip: yup
    .string()
    .required("Zip code is required")
    .matches(/^\d{5}$/, "Zip code must be exactly 5 digits"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  profileImage: yup
    .mixed()
    .required("Profile image is required")
});

export const MyProfile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const [previewImage, setPreviewImage] = useState<any>('');

  const onSubmit = async (data: any) => {
    console.log("Form data submitted: ", data);
    const response = await API({
      api: `customer/edit_profile`,
      body: data,
      type: 'POST'
    });

      
  };

  // Function to handle the image preview
  const handleImageChange = (e: any) => {
    const file = e?.target?.files && e?.target.files.length ? e?.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <h2>My Profile</h2>
      <p>Update your photo and personal details here</p>

      <div className="d-flex align-items-center mb-3">
        <img
          src={previewImage || "path/to/avatar.png"}
          alt="Avatar"
          className="rounded-circle"
          width="100"
          height="100"
        />
        <Form.Group className="ml-3">
          <Form.Control
            type="file"
            {...register("profileImage")}
            onChange={(e) => {
              handleImageChange(e);
              register("profileImage").onChange(e); // Ensures file is registered
            }}
            isInvalid={!!errors.profileImage}
          />
          <Form.Control.Feedback type="invalid">
            {errors.profileImage?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </div>

      <Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <br />
              <Form.Label>harpreet.singh@bridgingtech.com</Form.Label>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <br />
              <Form.Label>+1-589635-985</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="dl_number">
              <Form.Label>Driver's License Number:</Form.Label>
              <br />
              <Form.Label>96-58-65789</Form.Label>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="ssn_number">
              <Form.Label>SSN/EIN Number:</Form.Label>
              <br />
              <Form.Label>******8144</Form.Label>
            </Form.Group>
          </Col>
        </Row>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                {...register("dob")}
                isInvalid={!!errors.dob}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dob?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your address"
                {...register("address")}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="zip">
              <Form.Label>Zip code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter zip code"
                {...register("zip")}
                isInvalid={!!errors.zip}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zip?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                {...register("city")}
                isInvalid={!!errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                {...register("state")}
                isInvalid={!!errors.state}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="warning" type="submit" className="text-white mt-4">
          Save
        </Button>
      </Form>
    </>
  );
};
